import { combineReducers } from 'redux';
import dateRangeFilterReducer from 'redux/slices/dateRangeFilter';
import orderConfirmationReducer from './orderConfirmation';

const rootReducer = combineReducers({
  dateRangeFilter: dateRangeFilterReducer,
  orderConfirmation: orderConfirmationReducer
});

export default rootReducer;
