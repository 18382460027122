import Loader from 'components/Loader';
import { RouteName } from 'constants/routes';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';

const Login = lazy(() => import('pages/auth/Login'));
const Forgot = lazy(() => import('pages/auth/Forgot'));

const PDC = lazy(() => import('pages/dashboard/PDC'));
const OrderConfirmation = lazy(() => import('pages/dashboard/OrderConfirmation'));
const InvoiceInfo = lazy(() => import('components/Invoices/LfbidDetailsInvoices'));
const OrderInfo = lazy(() => import('components/OrderConfirmation/LfbidDetails'));

const Routes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <PublicRoute exact path={RouteName.login} component={Login} />
        <PublicRoute exact path={RouteName.forgot} component={Forgot} />

        <ProtectedRoute exact path={RouteName.pdc} component={PDC} />
        <ProtectedRoute exact path={RouteName.orderConfirmation} component={OrderConfirmation} />
        <ProtectedRoute
          exact
          path={`${RouteName.orderConfirmation}/InvoiceDetails`}
          component={InvoiceInfo}
        />
        <ProtectedRoute
          exact
          path={`${RouteName.orderConfirmation}/OrderDetails`}
          component={OrderInfo}
        />
        <Route path="/">
          <Redirect to={RouteName.login} />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
