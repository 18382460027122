/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dateRange: {
    startDate: null,
    endDate: null
  }
};

export const dateRangeFilterSlice = createSlice({
  name: 'dateRangeFilter',
  initialState,
  reducers: {
    dateRange: (state, { payload }) => {
      state.dateRange = {
        startDate: payload[0],
        endDate: payload[1]
      };
    },
    resetDate: (state) => {
      state.dateRange.startDate = null;
      state.dateRange.endDate = null;
    },
    reloader: (state) => {
      state.dateRange = { ...state.dateRange };
    }
  }
});

export const { dateRange, resetDate, reloader } = dateRangeFilterSlice.actions;

export default dateRangeFilterSlice.reducer;
