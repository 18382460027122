import { Image, Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import order_icon from 'assets/orders.svg';
import pdc_icon from 'assets/pdc.svg';
import { RouteName } from 'constants/routes';
import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import style from './style.module.scss';

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const history = useHistory();
  const { path: openedPath } = useRouteMatch();

  return (
    <Sider
      className={style['sidebar-wrapper']}
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}>
      <Menu defaultSelectedKeys={[openedPath]} mode="inline">
        <Menu.Item
          onClick={() => history.push(RouteName.pdc)}
          key={RouteName.pdc}
          icon={<Image preview={false} src={pdc_icon} className={style.icon} />}>
          PDC
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push(RouteName.orderConfirmation)}
          key={RouteName.orderConfirmation}
          icon={<Image preview={false} src={order_icon} className={style.icon} />}>
          Orders
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
