import { useEffect } from 'react';
import { windowReload } from 'utils/generic';

const AutoRefresh = () => {
  useEffect(() => {
    const prevDate = localStorage.getItem('refreshedDate');
    const currDate = new Date().toDateString();
    if (prevDate !== currDate) {
      localStorage.setItem('refreshedDate', currDate);
      windowReload();
    }
  });

  return null;
};

export default AutoRefresh;
