import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import style from './style.module.scss';

const Loader = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  return (
    <div className={style['loader-wrapper']}>
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Loader;
