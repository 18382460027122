import AutoRefresh from 'components/AutoRefresh';
import Loader from 'components/Loader';
import { Context } from 'context';
import { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';

const App = () => {
  const { isLoading } = useContext(Context);

  return (
    <>
      {isLoading ? <Loader /> : null}
      <AutoRefresh />

      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </>
  );
};

export default App;
