export const RouteName = {
  // <<< Auth >>>
  login: '/auth/login',
  forgot: '/auth/forgot',

  // <<< Dashboard >>>
  pdc: '/dashboard/pdc',
  orderConfirmation: '/dashboard/orderConfirmation',
  invoiceDetail: '/dashboard/orderConfirmation/InvoiceDetails',
  orderDetails: '/dashboard/orderConfirmation/OrderDetails'
};
