import { message } from 'antd';
import { logout } from 'api/auth';
import axios from 'axios';
import { HTTP_STATUS } from 'constants/globalConstant';
import PropTypes from 'prop-types';

const OFFLINE = process.env.REACT_APP_OFFLINE_BACKEND;
const OFFLINE_BASE_URL = process.env.REACT_APP_OFFLINE_URL;
const APICaller = ({ method, url, offurl, data, params, offlineAccept = false }) => {
  const axiosProps = {
    method,
    baseURL: OFFLINE === 'true' && offlineAccept ? OFFLINE_BASE_URL : process.env.REACT_APP_API_URL,
    url: OFFLINE === 'true' && offlineAccept ? offurl : url
  };

  if (data) axiosProps.data = data;

  if (params) axiosProps.params = params;

  if (localStorage.accessToken) {
    axiosProps.headers = {
      Authorization: `bearer ${localStorage.accessToken}`
    };
  }

  return new Promise((resolve, reject) => {
    axios({
      ...axiosProps
    })
      .then((res) => {
        const { data: { resp, data: response } = {} } = res || {};
        /*
        This is done because of the authentication api
        We are getting response in `resp` for the authentication api(s).
        */
        if (resp?.code >= 400) {
          message.error(resp?.msg);
          reject(resp?.msg);
        }

        resolve(resp || response);
      })
      .catch((err) => {
        const { data: errData, status } = err?.response || {};

        message.error(errData?.message || errData?.resp?.msg || 'Something went wrong!');

        if (status === HTTP_STATUS.FORBIDDEN || status === HTTP_STATUS.UNAUTHORIZED) {
          logout();
        }
        reject(err.message);
      });
  });
};

APICaller.prototype = {
  method: PropTypes.oneOf(['get', 'post']).isRequired,
  url: PropTypes.string.isRequired,
  data: PropTypes.any,
  params: PropTypes.any
};

export default APICaller;
