import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import { RouteName } from 'constants/routes';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import style from './style.module.scss';

const ProtectedRoute = ({ exact, path, component: Component }) => {
  const token = localStorage.accessToken || false;

  if (!token) {
    return <Redirect to={RouteName.login} />;
  }

  return (
    <Route exact={exact} path={path}>
      <Header />
      <div className={style['protected-container']}>
        <Sidebar />

        <section className={style.component}>
          <Component />
        </section>
      </div>
    </Route>
  );
};

ProtectedRoute.proptype = {
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired
};

export default ProtectedRoute;
