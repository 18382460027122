import { RouteName } from 'constants/routes';
import { Redirect, Route } from 'react-router-dom';

const PublicRoute = ({ exact, path, component }) => {
  const token = localStorage.accessToken || false;

  if (token) {
    return <Redirect to={RouteName.pdc} />;
  }

  return <Route exact={exact} path={path} component={component} />;
};

export default PublicRoute;
