import APICaller from 'utils/APICaller';
import { windowReload } from 'utils/generic';

export const login = (values) => {
  return new Promise((resolve, reject) => {
    APICaller({ method: 'post', url: '/apiuser/login', data: values })
      .then(({ data }) => {
        const { accessToken } = data || {};
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('userData', JSON.stringify(data));

        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    APICaller({ method: 'get', url: 'apiuser/logout' })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        localStorage.clear();
        windowReload();
      });
  });
};
