import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import APICaller from 'utils/APICaller';

const initialState = {
  provisionalOrdersData: {},
  invoiceOrdersData: {},
  historyOrdersData: {},
  lfbIdData: {},
  paymentDeatilsData: {},
  tableReloader: false,
  uploadInvoiceButtonState: false
};

export const provisionalOrders = createAsyncThunk('order/provisional', async ({ params }) => {
  const response = await APICaller({
    method: 'get',
    url: 'apifabric/orders/provisional',
    offurl: '/dummy/table',
    params
    // offlineAccept: true
  });
  return response;
});
export const invoiceOrders = createAsyncThunk('order/invoices', async ({ params }) => {
  const response = await APICaller({
    method: 'get',
    url: 'apifinance/invoice',
    params
  });
  return response;
});

export const historyOrders = createAsyncThunk('order/history', async ({ params }) => {
  const response = await APICaller({
    method: 'get',
    url: 'apifinance/payment',
    offurl: '/dummy/tablehis',
    params
    // offlineAccept: true
  });
  return response;
});

export const getLfbIdData = createAsyncThunk('order/LfbIdData', async (orderId) => {
  const response = await APICaller({
    method: 'get',
    url: `apifinance/order/${orderId}`,
    offurl: '/dummy/lfbid'
    // offlineAccept: true
  });
  return response;
});

export const getPaymentDetails = createAsyncThunk('order/paymentDetails', async (fabricId) => {
  const response = await APICaller({
    method: 'get',
    url: `apifinance/payment/fabrics/${fabricId}`,
    offurl: '/dummy/receivedamt'
    // offlineAccept: true
  });
  return response;
});

export const cancelOrder = createAsyncThunk('order/cancelOrder', async ({ orderId, remarks }) => {
  const response = await APICaller({
    method: 'patch',
    url: `apifabric/orders/provisional/${orderId}/cancel`,
    data: { remarks }
  });
  return response;
});

export const uploadInvoice = createAsyncThunk('order/uploadInvoice', async (formData) => {
  const response = await APICaller({
    method: 'patch',
    url: 'apifinance/invoice',
    data: formData
  });
  return response;
});

export const capturePayment = createAsyncThunk('order/capturePayment', async (formdata) => {
  const response = await APICaller({ method: 'post', url: 'apifinance/payment', data: formdata });
  return response;
});

export const approvePayment = createAsyncThunk(
  'order/approvePayment',
  async ({ form, orderId }) => {
    const response = await APICaller({
      method: 'patch',
      url: `apifabric/orders/provisional/${orderId}/approve`,
      data: form
    });

    return response;
  }
);

export const orderConfirmationSlice = createSlice({
  name: 'orderConfirmation',
  initialState,
  reducers: {
    tableReloader: (state) => {
      state.tableReloader = !state.tableReloader;
    },
    resetLfbidData: (state) => {
      state.lfbIdData = {};
    }
  },
  extraReducers: {
    [provisionalOrders.fulfilled]: (state, { payload }) => {
      state.provisionalOrdersData = payload;
    },
    [invoiceOrders.fulfilled]: (state, { payload }) => {
      state.invoiceOrdersData = payload;
    },
    [uploadInvoice.pending]: (state) => {
      state.uploadInvoiceButtonState = true;
    },
    [uploadInvoice.rejected]: (state) => {
      state.uploadInvoiceButtonState = false;
    },
    [uploadInvoice.fulfilled]: (state) => {
      message.success('Invoice Uploaded Successful !');
      state.uploadInvoiceButtonState = false;
    },
    [historyOrders.fulfilled]: (state, { payload }) => {
      state.historyOrdersData = payload;
    },
    [getLfbIdData.fulfilled]: (state, { payload }) => {
      state.lfbIdData = payload;
    },
    [getPaymentDetails.fulfilled]: (state, { payload }) => {
      state.paymentDeatilsData = payload;
    },
    [cancelOrder.fulfilled]: () => {
      message.success('Payment Cancelled Successful !');
    },
    [capturePayment.fulfilled]: () => {
      message.success('Payment Captured Successful !');
    },
    [approvePayment.fulfilled]: () => {
      message.success('Payment Approved Successful !');
    }
  }
});

export const { tableReloader, resetLfbidData } = orderConfirmationSlice.actions;

export default orderConfirmationSlice.reducer;
