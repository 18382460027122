import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Image, Popover, Tooltip } from 'antd';
import { logout } from 'api/auth';
import logo from 'assets/logo.svg';
import { Context } from 'context';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import style from './style.module.scss';

const Header = () => {
  const history = useHistory();

  const { userData = {} } = useContext(Context);

  const content = (
    <div className={style['avatar-content']}>
      {userData.image && (
        <Image className={style.image} preview={false} src={userData.image} alt="" />
      )}

      <Tooltip title={userData.name}>
        <p className={style.name}>{userData.name}</p>
      </Tooltip>

      <Tooltip title={userData.email}>
        <p className={style.email}>{userData.email}</p>
      </Tooltip>

      <Button className={style.button} onClick={logout}>
        Log Out
      </Button>
    </div>
  );

  return (
    <header className={style.header}>
      <div onClick={() => history.go(0)} onKeyDown className={style.logo}>
        <img src={logo} alt="" />
      </div>

      <Popover placement="bottomRight" content={content} trigger="click">
        <Avatar
          icon={
            userData?.image ? (
              <Image preview={false} src={userData.image} alt="" />
            ) : (
              <UserOutlined />
            )
          }
        />
      </Popover>
    </header>
  );
};

export default Header;
