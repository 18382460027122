import { createContext, useEffect, useState } from 'react';

export const Context = createContext();

const State = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (!localStorage.accessToken) return;
    if (Object.keys(userData).length > 0) return;

    setUserData(JSON.parse(localStorage.userData || '{}'));
  }, []);

  return (
    <Context.Provider value={{ isLoading, setIsLoading, userData, setUserData }}>
      {children}
    </Context.Provider>
  );
};

export default State;
